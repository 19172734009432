<template>
  <div class="container">
    <!-- <div class="navBar">
      <div class="navItem" @click="$router.push('/productFeedback')">新产品需求</div>
      <div class="navItem active">产品信息咨询</div>
    </div> -->
    <div class="title">
      <img src="@/assets/images/p_gzh_icon@2x.png" />
      <span>产品信息咨询</span>
    </div>
    <div class="tips">
      <span>请留下您的联系方式，<br/>客服专员将在收到需求后尽快与您联系。</span>
    </div>
    <div class="how">我们如何联系您？</div>
    <div class="item">
      <div class="icon">
        <img class="doctorIcon" src="@/assets/images/doctor_gzh_icon@2x.png" />
      </div>
      <div class="inputBox">
        <input type="text" v-model="userName" placeholder="请输入您的姓名" />
      </div>
    </div>
    <div class="item">
      <div class="icon">
        <img class="iphoneIcon" src="@/assets/images/iphone_comm_icon@2x.png" />
      </div>
      <div class="inputBox">
        <span class="prefix">+86</span>
        <input
          type="number"
          v-model="phoneNumber"
          placeholder="请输入手机号码"
        />
      </div>
    </div>

    <div class="how">您想缝合的层次是什么？</div>
    <div class="item">
      <div class="icon">
        <img class="doctorIcon" src="@/assets/images/fhcc_gzh_icon@2x.png" />
      </div>
      <div class="inputBox">
        <van-field
          v-if="this.userInfo.Type == 1"
          readonly
          class="timeinput"
          clickable
          :value="suture"
          placeholder="请选择"
          @click="sutureShow = true"
        />
        <input v-else type="text" v-model="suture" placeholder="请输入" />
        <img class="down" src="@/assets/images/pulldown_sign_icon@2x.png" />
        <van-popup v-model="sutureShow" round position="bottom">
          <van-picker
            title=""
            show-toolbar
            :columns="sutureList"
            value-key="Content"
            @cancel="sutureShow = false"
            @confirm="changeSuture"
          />
        </van-popup>
      </div>
    </div>

    <div class="how">您想试用的产品是什么？</div>
    <div class="item">
      <div class="icon">
        <img class="doctorIcon" src="@/assets/images/productIcon.png" />
      </div>
      <div class="inputBox">
        <van-field
          readonly
          class="timeinput"
          clickable
          :value="product"
          placeholder="请选择"
          @click="productShow = true"
        />
        <img class="down" src="@/assets/images/pulldown_sign_icon@2x.png" />
        <van-popup v-model="productShow" round position="bottom">
          <van-picker
            title=""
            show-toolbar
            :columns="productList"
            value-key="Content"
            @cancel="productShow = false"
            @confirm="changeProduct"
          />
        </van-popup>
      </div>
    </div>

    <div class="how">什么时候方便联系您？</div>
    <div class="item">
      <div class="icon">
        <img class="doctorIcon" src="@/assets/images/time_gzh_icon@2x.png" />
      </div>
      <div class="inputBox">
        <van-field
          readonly
          class="timeinput"
          clickable
          :value="Time"
          placeholder="请选择"
          @click="showtime = true"
        />
        <img class="down" src="@/assets/images/pulldown_sign_icon@2x.png" />
        <van-popup v-model="showtime" round position="bottom">
          <van-picker
            title=""
            show-toolbar
            :columns="timeList"
            @cancel="showtime = false"
            @confirm="changetime"
          />
        </van-popup>
      </div>
    </div>

    <div class="declare" @click="checkedDeclare">
      <img :src="isCheckedDeclare ? require('@/assets/images/system-complete@2x.png') : require('@/assets/images/dis_system_complete_icon@2x.png')"/>
      <span>若您希望进一步参与与本平台直播内容相关的讨论及互动，请您提供以上个人信息字段。强生承诺仅为上述目的收集和处理您提供的以上个人信息字段并将按照相关法律法规的要求保护您的个人信息。您已明确知晓和同意向强生提供和由强生处理以上个人信息。您同意仅提供本人信息，不会提供他人的个人信息。</span>
    </div>

    <div class="btn" @click="submit">提交</div>
  </div>
</template>

<script>
import utils from "../../common/utils/utils";
import { mapState } from "vuex";
export default {
  name: "NewProduct",
  data() {
    return {
      userName: "", // 姓名
      phoneNumber: "", // 手机号
      product: "",
      productList: [
        "薇乔",
        "快薇乔",
        "抗菌薇乔",
        "普迪思",
        "抗菌普迪思",
        "单乔",
        "普理灵",
        "免打结倒刺线",
        "鱼骨倒刺线",
        "多抹棒",
        "爱惜邦",
        "ZipFix",
        "其他"
      ],
      productShow: false,
      suture: "",
      sutureList: [],
      sutureShow: false,
      Time: "",
      timeList: [
        "周一 上午",
        "周一 下午",
        "周二 上午",
        "周二 下午",
        "周三 上午",
        "周三 下午",
        "周四 上午",
        "周四 下午",
        "周五 上午",
        "周五 下午",
      ],
      showtime: false,
      isCheckedDeclare: false
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.userName = this.userInfo.RealName;
    this.phoneNumber = this.userInfo.Phone;
    this.getSuture();
  },
  methods: {
    changeProduct(val) {
      this.product = val;
      this.productShow = false;
    },
    changeSuture(val) {
      this.suture = val.Content;
      this.sutureShow = false;
    },
    changetime(val) {
      this.Time = val;
      this.showtime = false;
    },
    getSuture() {
      this.$axios.post("/Api/Api/Web/ContactUs/GetArrangements?SubCode=" + this.userInfo.SubjectCode).then((res) => {
        this.sutureList = res.Data;
      });
    },
    checkedDeclare() {
      this.isCheckedDeclare = !this.isCheckedDeclare;
    },
    submit: utils.debounce(function() {
      if (!this.userName) {
        this.$toast("请输入姓名！");
        return false;
      }

      if (this.phoneNumber) {
        let mobile_regex = /^(?:(?:\+|00)86)?1\d{10}$/;
        if (!mobile_regex.test(this.phoneNumber)) {
          this.$toast("请输入正确的手机号码！");
          return false;
        }
      } else {
        this.$toast("请输入手机号码！");
        return false;
      }

      if (!this.suture) {
        this.$toast("请选择缝合层次！");
        return false;
      }

      if (!this.Time) {
        this.$toast("请选择联系时间！");
        return false;
      }

      if (!this.isCheckedDeclare) {
        this.$toast("请勾选协议！");
        return false;
      }

      this.$axios
        .post("/Api/Api/Web/ContactUs/AddContactUs", {
          UName: this.userName,
          Phone: this.phoneNumber,
          Intend: this.product,
          Product: this.suture,
          Time: this.Time,
          Help: "新产品试用",
        })
        .then((res) => {
          if (res.RetCode == '10000') {
            this.$dialog.alert({
              message: "提交成功！",
            });
          } else {
            this.$toast(res.RetMsg);
          }
          
        });
    }, 1000)
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
  padding: 12px;
  box-sizing: border-box;
}
.navBar {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 45px;
    // border: 1px solid red;
    background-color: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
    .navItem {
      flex: 1;
      text-align: center;
      line-height: 45px;
      font-size: 15px;
      color: #333333;
    }
    .active {
      background-color: #CA001B;
      color: #FFFFFF;
    }
}
.down {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  width: 4%;
}
.logo {
  width: 70px;
  display: block;
  margin: 0 auto;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin: 60px 0 10px;
  color: #CA001B;
  font-weight: bolder;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
.how {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333333;
}

.item {
  display: flex;
  height: 48px;
  line-height: 48px;
  border: 0.5px solid #ffffff;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #f3f5f5;
  position:relative;
  .icon {
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iphoneIcon {
      width: 15px;
    }
    .doctorIcon {
      width: 18px;
    }
  }
  .icon::after {
    content: "";
    width: 1px;
    height: 30px;
    background-color: #ffffff;
    position: absolute;
    top: 9px;
    right: 0px;
  }
  .inputBox {
    display: flex;
    flex: 1;
    .prefix {
      position: relative;
      padding: 0 8px 0 10px;
    }
    .prefix::after {
      content: "";
      width: 1px;
      height: 16px;
      background-color: #333333;
      position: absolute;
      top: 16px;
      right: 0px;
    }
    input {
      flex: 1;
      padding: 0 8px;
      border: none;
      box-sizing: border-box;
      background: transparent;
      color: #333333;
    }
  }
}
.tips {
  margin-bottom: 50px;
  text-align: center;
  img {
    width: 10px;
    height: 10px;
    margin-right: 3px;
    vertical-align: middle;
  }
  span {
    font-size: 13px;
    color: #ababab;
  }
}
.declare {
  display: flex;
  font-size: 11px;
  color: #666666;
  margin-top: 15px;
  img {
    display: block;
    width: 14px;
    height: 14px;
    margin: 2px 5px 0 10px;
  }
}
.btn {
  width: 100%;
  height: 48px;
  line-height: 48px;
  color: #ffffff;
  font-size: 16px;
  background: #ca001b;
  border-radius: 4px;
  border: none;
  text-align: center;
  margin: 20px auto;
}

.van-cell{
  background: unset;
}

/*webkit 内核浏览器*/
input::-webkit-input-placeholder{
  font-size: 15px;
  color: #cccccc;
}
/*Mozilla Firefox 19+*/
input::-moz-placeholder{
  font-size: 15px;
  color: #cccccc;
}
/*Mozilla Firefox 4 to 18*/
input:-moz-placeholder{
  font-size: 15px;
  color: #cccccc;
}
/*Internet Explorer 10+*/
input:-ms-input-placeholder{
  font-size: 15px;
  color: #cccccc;
}
</style>